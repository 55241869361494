export default {
  title: {
    header: '買い物履歴',
    label_receipt_btn: '領収書',
    confirm: '注文情報確認',
  },
  table: {
    shipping_fee: '配送料',
    total_price: '合計金額',
    payment_status: '支払状況',
    delivery_status: '配送状況',
  },
  file: {
    name: '領収書',
  },
  label: {
    home_delivery: '自宅配送',
    product: '商品',
    quantity: '数量',
    amount: '金額',
    tax_included: '(税込)',
    product_total_price: '商品合計金額',
    delivery_charge: '配送料',
    postal_mark: '〒',
    address: '〒{address}',
    quantity_sp: '数量: {quantity}',
    total: '合計金額',
    payment: 'カードで支払う',
    show_onetime_account: '振込先はこちら',
    onetime_account_popup_title: '振込先情報',
  },
  message: {
    note: '※こちらの情報でよろしければ次へのボタンをクリックしてください。決済画面へ進みます。',
  },
  payment_status: {
    unpaid: '未払',
    on_hold: '保留',
    paid: '支払済',
    cancel: 'キャンセル',
  },
  delivery_status: {
    undispatched: '未発送',
    arranged: '手配中',
    completion: '完了',
    on_hold: '保留',
  },
  onetime: {
    bank_transfer: '銀行振込',
    bank_transfer_success: '「銀行振込」を選択されたお客様のお振込先は以下のとおりです。',
    bank_transfer_info: '【振込先情報】',
    bank_paypay: 'PayPay銀行',
    branch_number: '店番号',
    branch_name: '支店名',
    branch_label: '支店',
    account_number: '口座番号',
    account_name: '振込先名義人名',
    note_1: '※こちらは、お客様の注文専用に割り当てられた口座情報となります。',
    note_2: '※こちら口座には有効期限(10日間程度)がございますので、お早めのお振込をお願いいたします。',
    note_1_success: '※こちらの口座情報は、注文ごとに異なる番号が発行されます。',
  },
};
